define("fap/routes/libraries/library/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrariesLibrarySortRoute extends Ember.Route {
    model() {
      const {
        library_id
      } = this.paramsFor("libraries.library");
      return this.store.query("medium", {
        library: library_id,
        toSort: true,
        sort: "random",
        n: 1
      }).then(res => {
        if (res.length === 1) {
          return res.objectAt(0);
        }

        return res;
      });
    }

  }

  _exports.default = LibrariesLibrarySortRoute;
});
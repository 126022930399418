define("fap/routes/libraries/library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrariesLibraryRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord('library', params.library_id);
    }

    afterModel() {
      Ember.getOwner(this).lookup("service:stats").refresh();
    }

  }

  _exports.default = LibrariesLibraryRoute;
});
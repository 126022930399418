define("fap/serializers/library", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrarySerializer extends _jsonApi.default {}

  _exports.default = LibrarySerializer;
});
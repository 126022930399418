define("fap/components/list-group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li class="list-group-item">{{yield}}</li>
  
  
  */
  {
    id: "jSXNaoM9",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"list-group-item\"],[8],[14,1],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "fap/components/list-group-item.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
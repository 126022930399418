define("fap/components/list-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="list-group">
    {{yield}}
  </ul>
  
  */
  {
    id: "/jYbI4QR",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"list-group\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "fap/components/list-group.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
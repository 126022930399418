define("fap/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LUPe+Ig8",
    "block": "{\"symbols\":[\"alert\"],\"statements\":[[7,\"nav\",true],[10,\"class\",\"navbar navbar-expand-md navbar-dark bg-dark fixed-top\"],[8],[0,\"\\n  \"],[5,\"link-to\",[[12,\"class\",\"navbar-brand\"]],[[\"@route\"],[\"application\"]],{\"statements\":[[0,\"fap\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"navbar-toggler\"],[10,\"data-toggle\",\"collapse\"],[10,\"data-target\",\"#navbarsExampleDefault\"],[10,\"aria-controls\",\"navbarsExampleDefault\"],[10,\"aria-expanded\",\"false\"],[10,\"aria-label\",\"Toggle navigation\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"navbar-toggler-icon\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"collapse navbar-collapse\"],[10,\"id\",\"navbarsExampleDefault\"],[8],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"navbar-nav mr-auto\"],[8],[0,\"\\n\"],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"alerts\"],[10,\"class\",\"w-75 w-md-50 w-lg-25\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"alerts\",\"alerts\"]]],null,{\"statements\":[[0,\"  \"],[5,\"bs-alert\",[],[[\"@type\",\"@visible\",\"@onDismiss\",\"@onDismissed\"],[[23,1,[\"type\"]],[23,1,[\"visible\"]],[28,\"fn\",[[23,0,[\"alerts\",\"dismiss\"]],[23,1,[\"idx\"]]],null],[28,\"fn\",[[23,0,[\"alerts\",\"dismissed\"]],[23,1,[\"idx\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[1,[23,1,[\"msg\"]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/application.hbs"
    }
  });

  _exports.default = _default;
});
define("fap/templates/libraries/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCCC8xhJ",
    "block": "{\"symbols\":[\"lib\",\"@model\"],\"statements\":[[7,\"main\",true],[10,\"role\",\"main\"],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row align-items-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"Libraries\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row align-items-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-4\"],[8],[0,\"\\n      \"],[5,\"list-group\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[5,\"list-group-item\",[],[[],[]],{\"statements\":[[0,\"\\n          \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"libraries.library\",[23,1,[]]]],{\"statements\":[[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[2,\" /.container \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/libraries/index.hbs"
    }
  });

  _exports.default = _default;
});
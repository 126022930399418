define("fap/routes/libraries/library/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrariesLibraryTrashRoute extends Ember.Route {
    model() {
      return this.store.query("medium", {
        library: this.paramsFor("libraries.library").library_id,
        sort: "DeletedAt",
        inTrash: true
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.library = this.paramsFor("libraries.library").library_id;
    }

  }

  _exports.default = LibrariesLibraryTrashRoute;
});
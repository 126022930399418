define("fap/templates/libraries/library/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "umYBGKUK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-right\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn btn-light\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"empty\"]]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[\"recycle\"]]],[0,\" Empty Trash\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[5,\"media-grid\",[],[[\"@content\",\"@actionHandlers\"],[[23,0,[\"filteredContent\"]],[23,0,[\"gridActions\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/libraries/library/trash.hbs"
    }
  });

  _exports.default = _default;
});
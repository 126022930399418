define("fap/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oeXbgMdX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"form\",false],[12,\"class\",\"row flex-column align-items-center text-center mt-5\"],[3,\"on\",[\"submit\",[23,0,[\"login\"]]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\",\"@size\"],[\"lock\",\"10x\"]]],[0,\"\\n    \"],[5,\"input\",[],[[\"@type\",\"@value\",\"@classNames\",\"@placeholder\"],[\"password\",[23,0,[\"password\"]],\"form-control form-control-lg col col-md-6 mt-5\",\"password\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/login.hbs"
    }
  });

  _exports.default = _default;
});
define("fap/routes/libraries/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrariesIndexRoute extends Ember.Route {
    model() {
      return this.store.findAll("library");
    }

  }

  _exports.default = LibrariesIndexRoute;
});
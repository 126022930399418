define("fap/controllers/libraries/library/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LibrariesLibrarySortController = (_class = (_temp = class LibrariesLibrarySortController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "alerts", _descriptor, this);

      _initializerDefineProperty(this, "stats", _descriptor2, this);

      _initializerDefineProperty(this, "undoData", _descriptor3, this);
    }

    async doUndo() {
      if (!this.undoData) {
        return;
      }

      if (this.undoData.action === "restore") {
        this.undoData.model.deletedAt = undefined;
      } else if (this.undoData.action === "tosort") {
        this.undoData.model.toSort = true;
      }

      await this.undoData.model.save().then(() => {
        this.model = this.undoData.model;
        this.undoData = undefined;
        this.stats.refresh();
      }).catch(reason => {
        this.alerts.error(`Cannot undo action: ${reason.message}`);
      });
    }

    async deleteImg() {
      this.model.deletedAt = new Date();
      await this.model.save().then(() => {
        this.undoData = {
          action: "restore",
          model: this.model
        };
        this.newImg();
        this.stats.refresh();
      }).catch(reason => {
        this.alerts.error(`Cannot delete image: ${reason.message}`);
      });
    }

    async skipImg() {
      this.undoData = undefined;
      await this.newImg();
    }

    keepImg() {
      this.model.toSort = false;
      this.model.save().then(() => {
        this.undoData = {
          action: "tosort",
          model: this.model
        };
        this.newImg();
        this.stats.refresh();
      }).catch(reason => {
        this.alerts.error(`cannot move image: ${reason.message}`);
      });
    }

    async newImg() {
      await this.store.query("medium", {
        library: this.model.library,
        toSort: true,
        sort: "random",
        n: 1
      }).then(res => {
        if (res.length === 1) {
          this.model = res.objectAt(0);
          return;
        }

        this.model = undefined;
      }).catch(reason => {
        this.alerts.error(`Cannot delete image: ${reason.message}`);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alerts", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stats", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "undoData", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "doUndo", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "doUndo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteImg", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteImg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "skipImg", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "skipImg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keepImg", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "keepImg"), _class.prototype)), _class);
  _exports.default = LibrariesLibrarySortController;
});
define("fap/templates/libraries/library/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HrgTMqjO",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"media-grid\",[],[[\"@content\",\"@actionHandlers\"],[[23,1,[]],[23,0,[\"gridActions\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/libraries/library/index.hbs"
    }
  });

  _exports.default = _default;
});
define("fap/routes/libraries/library/favourites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrariesLibraryFavouritesRoute extends Ember.Route {
    model() {
      return this.store.query("medium", {
        library: this.paramsFor("libraries.library").library_id,
        isFavourite: true,
        sort: "CreatedAt"
      });
    }

  }

  _exports.default = LibrariesLibraryFavouritesRoute;
});
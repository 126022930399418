define("fap/controllers/libraries/library/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LibrariesLibraryTrashController = (_class = (_temp = class LibrariesLibraryTrashController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "alerts", _descriptor, this);

      _initializerDefineProperty(this, "stats", _descriptor2, this);

      _defineProperty(this, "library", undefined);
    }

    get filteredContent() {
      return this.model.filter(it => it.isInTrash);
    }

    get gridActions() {
      return {
        restore: this.restoreImg
      };
    }

    async empty() {
      const body = new FormData();
      body.set('library', this.library);
      await window.fetch(`/api/libraries/${this.library}/empty`, {
        method: 'POST',
        body: body
      }).then(response => {
        if (response.ok) {
          this.stats.refresh();
          return this.model.update();
        } else {
          return response.text().then(t => {
            this.alerts.error(t);
          });
        }
      }).catch(reason => {
        this.alerts.error(reason.message);
      });
    }

    async restoreImg(media) {
      media.deletedAt = undefined;
      await media.save().then(() => {
        this.stats.refresh();
      }).catch(reason => {
        this.alerts.error(`Cannot restore image: ${reason.message}`);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alerts", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stats", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "empty", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "empty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "restoreImg", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "restoreImg"), _class.prototype)), _class);
  _exports.default = LibrariesLibraryTrashController;
});
define("fap/routes/libraries/library/img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrariesLibraryImgRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord("medium", params.media_id);
    }

  }

  _exports.default = LibrariesLibraryImgRoute;
});
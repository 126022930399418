define("fap/components/media-viewer", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <main role="main" id="viewer">
    <button {{on "click" this.close}} type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <img src={{@this.media.url}} alt={{this.model.id}} />
    {{yield}}
  </main>
  
  
  */
  {
    id: "F4S0gq+Q",
    block: "{\"symbols\":[\"@media\",\"&default\"],\"statements\":[[7,\"main\",true],[10,\"role\",\"main\"],[10,\"id\",\"viewer\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"close\"],[12,\"aria-label\",\"Close\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"close\"]]]],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"\xD7\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[23,1,[\"url\"]]],[11,\"alt\",[23,0,[\"model\",\"id\"]]],[8],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "fap/components/media-viewer.hbs"
    }
  });

  let MediaViewerComponent = (_class = class MediaViewerComponent extends _component.default {
    close() {
      window.history.back();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "close", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
  _exports.default = MediaViewerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MediaViewerComponent);
});
define("fap/templates/libraries/library/favourites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qf7cxPG3",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,0,[\"model\",\"length\"]],false],[0,\"--\"],[1,[23,0,[\"filteredContent\",\"length\"]],false],[0,\"\\n\"],[5,\"media-grid\",[],[[\"@content\",\"@actionHandlers\"],[[23,0,[\"filteredContent\"]],[23,0,[\"gridActions\"]]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/libraries/library/favourites.hbs"
    }
  });

  _exports.default = _default;
});
define("fap/templates/libraries/library/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xbzfuzRy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"\\n\"],[5,\"media-viewer\",[],[[\"@media\"],[[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"media-viewer/actions\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"undoData\"]]],null,{\"statements\":[[0,\"    \"],[5,\"media-viewer/action\",[],[[\"@onActivation\"],[[23,0,[\"doUndo\"]]]],{\"statements\":[[5,\"fa-icon\",[],[[\"@icon\"],[\"undo\"]]]],\"parameters\":[]}],[7,\"br\",true],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[5,\"media-viewer/action\",[],[[\"@onActivation\"],[[23,0,[\"deleteImg\"]]]],{\"statements\":[[5,\"fa-icon\",[],[[\"@icon\"],[\"times\"]]]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"media-viewer/action\",[],[[\"@onActivation\"],[[23,0,[\"skipImg\"]]]],{\"statements\":[[5,\"fa-icon\",[],[[\"@icon\"],[\"question\"]]]],\"parameters\":[]}],[0,\"\\n    \"],[5,\"media-viewer/action\",[],[[\"@onActivation\"],[[23,0,[\"keepImg\"]]]],{\"statements\":[[5,\"fa-icon\",[],[[\"@icon\"],[\"check\"]]]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"text-center pt-4\"],[8],[0,\"Nothing to do !\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/libraries/library/sort.hbs"
    }
  });

  _exports.default = _default;
});
define("fap/routes/libraries/library/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LibrariesLibraryIndexRoute extends Ember.Route {
    model() {
      return this.store.query("medium", {
        library: this.paramsFor("libraries.library").library_id,
        sort: "CreatedAt",
        toSort: false
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
    }

  }

  _exports.default = LibrariesLibraryIndexRoute;
});
define("fap/templates/libraries/library/img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gQgsykRA",
    "block": "{\"symbols\":[],\"statements\":[[5,\"media-viewer\",[],[[\"@media\"],[[23,0,[\"model\"]]]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fap/templates/libraries/library/img.hbs"
    }
  });

  _exports.default = _default;
});
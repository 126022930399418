define("fap/components/media-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id="media-grid" class="d-flex justify-content-aroun flex-wrap">
    {{#each @this.content as |item|}}
    <div class="p-2">
      <div class="card">
        <LinkTo @route="libraries.library.img" @model={{item}}>
          <img src={{item.url}} class="card-img-top" alt="thumb">
        </LinkTo>
        <div class="card-footer text-muted">
          {{#unless item.isInTrash}}
          <button {{on "click" (fn @actionHandlers.toggleFav item)}} class="btn btn-sm" type="button">
            <FaIcon @icon="star" @prefix={{if item.isFavourite "fas" "far"}} />
          </button>
          {{/unless}}
  
          {{#if item.isInTrash}}
          <FaIcon @icon="star" @prefix={{if item.isFavourite "fas" "far"}} />
          <button {{on "click" (fn @actionHandlers.restore item)}} class="btn btn-sm" type="button"><FaIcon @icon="trash-restore" /></button>
          {{/if}}
        </div>
      </div>
    </div>
    {{/each}}
  </div>
  {{yield}}
  
  */
  {
    id: "OTQ/fBP5",
    block: "{\"symbols\":[\"item\",\"@actionHandlers\",\"@content\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"media-grid\"],[10,\"class\",\"d-flex justify-content-aroun flex-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"p-2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card\"],[8],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"libraries.library.img\",[23,1,[]]]],{\"statements\":[[0,\"\\n        \"],[7,\"img\",true],[11,\"src\",[23,1,[\"url\"]]],[10,\"class\",\"card-img-top\"],[10,\"alt\",\"thumb\"],[8],[9],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card-footer text-muted\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"isInTrash\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[\"toggleFav\"]],[23,1,[]]],null]]],[8],[0,\"\\n          \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"star\",[28,\"if\",[[23,1,[\"isFavourite\"]],\"fas\",\"far\"],null]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,1,[\"isInTrash\"]]],null,{\"statements\":[[0,\"        \"],[5,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"star\",[28,\"if\",[[23,1,[\"isFavourite\"]],\"fas\",\"far\"],null]]]],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,2,[\"restore\"]],[23,1,[]]],null]]],[8],[5,\"fa-icon\",[],[[\"@icon\"],[\"trash-restore\"]]],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[14,4],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "fap/components/media-grid.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
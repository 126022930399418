define("fap/serializers/medium", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MediumSerializer extends _jsonApi.default {}

  _exports.default = MediumSerializer;
});
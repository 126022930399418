define("fap/services/session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SessionService extends Ember.Service {
    login(password) {
      const body = new FormData();
      body.set('password', password);
      return window.fetch('/login', {
        method: 'POST',
        body: body
      });
    }

  }

  _exports.default = SessionService;
});